import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-931cc57c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-list" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "number t-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_OptionMenu = _resolveComponent("OptionMenu")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_Header, { title: "数据看板" }),
          _createVNode(_component_OptionMenu, {
            options: _ctx.menus,
            modelValue: _ctx.checkedMenus,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedMenus) = $event))
          }, null, 8, ["options", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "content-grey" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templates, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "data-item",
                key: index
              }, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: require(`@/assets/images/member/dashboard/${index}.png`),
                    alt: "",
                    srcset: ""
                  }, null, 8, _hoisted_4)
                ]),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.allInfo[_ctx.checkedMenus] && _ctx.allInfo[_ctx.checkedMenus][item.dataKey]), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}