
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { getTongjiData } from "@/service/member";
import Header from "@/components/header.vue";
import OptionMenu from "@/components/optionMenu.vue";

export default {
  name: "DataDashboard",
  components: { OptionMenu, Header, IonHeader, IonContent, IonPage },
  setup() {
    const data = {
      menus: [
        {
          label: "总览",
          value: "alllist",
        },
        {
          label: "今日",
          value: "todaylist",
        },
        {
          label: "本周",
          value: "weeklist",
        },
        {
          label: "本月",
          value: "monthlist",
        },
        {
          label: "本年",
          value: "yearlist",
        },
      ],
      templates: [
        {
          title: "用户新增",
          dataKey: "usercount",
        },
        {
          title: "项目总数",
          dataKey: "projectcount",
        },
        {
          title: "留言总数",
          dataKey: "feedbackcount",
        },
        {
          title: "招投标总数",
          dataKey: "tendercount",
        },
        {
          title: "供应商总数",
          dataKey: "suppliercount",
        },
        {
          title: "工人总数",
          dataKey: "wordercount",
        },
        {
          title: "招聘总数",
          dataKey: "jobcount",
        },
        {
          title: "员工总数",
          dataKey: "systemcount",
        },
      ],
      checkedMenus: ref("alllist"),
      allInfo: ref({}) as any,
    };

    const getTongjiDataInfo = async () => {
      const result = await getTongjiData({});
      data.allInfo.value = result;
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      (data.checkedMenus = ref("alllist")), getTongjiDataInfo();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      (data.checkedMenus = ref("alllist")), console.log("onIonViewWillEnter!");
    });

    return { ...data };
  },
};
